<template>
  <div>
    <div class="home_head">
      <div class="home_head_content">
        <img class="animate__animated animate__1200" src="../../static/newImg2/home_bg2.png" width="535px" />
      </div>
      <img class="home_head_bg" src="../../static/newImg2/home_bg1.jpg">
    </div>
    <div class="home_map">
      <div class="home_map_left">
        <div class="home_map_left_title animate__animated animate__10s">浙江中峪农业发展有限责任公司</div>
        <div class="home_map_left_tips animate__animated animate__11s">我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，
          始终坚持在原产地种植，确保药材纯正品质。</div>
        <div class="home_map_left_num animate__animated animate__11s">
          <div class="home_map_left_num_left">
            <div class="num" id="ZZMJ"></div>
            <div class="tips">种植面积(亩)</div>
            <div class="num" id="XSDW"></div>
            <div class="tips">下属单位(家)</div>
          </div>
          <div class="home_map_left_num_right">
            <div class="num" id="GZRY"></div>
            <div class="tips">工作人员(人)</div>
            <div class="num" id="QDSJ"></div>
            <div class="tips">启动时间(年)</div>
          </div>
        </div>
        <div class="get_more animate__animated animate__12s">更多</div>
      </div>
      <div class="home_map_right animate__animated animate__11s">
        <img src="../../static/newImg2/home_map_bg1.png" width="100%" />
        <div class="home_map_right_introduction">
          <div class="home_map_right_introduction_bg">
            <div style="font-weight: bold;font-size: 20px;">浙江中峪农业发展有限责任公司</div>
            <div>浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-01室</div>
            <div>联系方式：400-185-3168</div>
          </div>
          <div class="home_map_right_introduction_name">浙江省</div>
          <div class="home_map_right_introduction_pointer"></div>
          <div class="home_map_right_introduction_flicker"></div>
        </div>
      </div>
    </div>
    <div class="home_module_3">
      <div class="imgs">
        <div class="imgs_item" :class="imgIndex == 0 ? ' active' : ''" @click="imgIndex = 0" data-index="0"
          @mouseover="changeImgIndex(0)"
          :style="'background-image: url(' + require('../../static/newImg2/home_module_3_bg1.jpg') + ');'"></div>
        <div class="imgs_item" :class="imgIndex == 1 ? ' active' : ''" @click="imgIndex = 1" data-index="1"
          @mouseover="changeImgIndex(1)"
          :style="'background-image: url(' + require('../../static/newImg2/home_module_3_bg2.jpg') + ');'"></div>
        <div class="imgs_item" :class="imgIndex == 2 ? ' active' : ''" @click="imgIndex = 2" data-index="2"
          @mouseover="changeImgIndex(2)"
          :style="'background-image: url(' + require('../../static/newImg2/home_module_3_bg3.jpg') + ');'"></div>
      </div>
      <div class="imgs_tips">
        <div class="imgs_tips_item" :class="imgIndex == 0 ? ' active' : ''" @click="imgIndex = 0" data-index="0"
          @mouseover="changeImgIndex(0)">
          <div class="imgs_tips_item_bg" v-show="imgIndex != 0">创新</div>
          <div class="imgs_tips_item_tips" v-show="imgIndex == 0">
            <div class="imgs_tips_item_tips_head">创新</div>
            我们专注于道地药材的种子种苗孵化培育、<br />
            科学精细化种植、研发、加工与销售。
          </div>
        </div>
        <div class="imgs_tips_item" :class="imgIndex == 1 ? ' active' : ''" @click="imgIndex = 1" data-index="1"
          @mouseover="changeImgIndex(1)">
          <div class="imgs_tips_item_bg" v-show="imgIndex != 1">纯正</div>
          <div class="imgs_tips_item_tips" v-show="imgIndex == 1">
            <div class="imgs_tips_item_tips_head">纯正</div>
            我们将积极拓展国际市场，将中华传统药材<br />文化推向世界舞台，让更多人了解和受益于中医药的神奇魅力。
          </div>
        </div>
        <div class="imgs_tips_item" :class="imgIndex == 2 ? ' active' : ''" @click="imgIndex = 2" data-index="2"
          @mouseover="changeImgIndex(2)">
          <div class="imgs_tips_item_bg" v-show="imgIndex != 2">中医</div>
          <div class="imgs_tips_item_tips" v-show="imgIndex == 2">
            <div class="imgs_tips_item_tips_head">中医</div>
            始终坚持在原产地种植，确保药材纯正品质，<br />将在全国范围内建立多个道地药材种植基地，
          </div>
        </div>
      </div>
    </div>
    <div class="home_module_4">
      <div class="home_module_4_content">
        <div class="home_module_4_content_title">全国野生药材基地中心</div>
        <div class="home_module_4_content_desc">浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。<br>
          我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，<br>
          将在全国范围内建立多个道地药材种植基地，严格遵循地域特性、自然规律和药材生长习性，确保药材在最佳生长环境中茁壮成长，致<br>
          力于为更多人提供高品质、纯天然的中药材，为传统中医药文化焕发新的活力。</div>
        <div class="home_module_4_content_more">全国公司网点</div>
      </div>
    </div>
    <div class="home_module_5">
    </div>
    <div class="home_module_6">
      <div v-for="item in treeData" :key="item.id" class="home_module_6_item">
        <img :src="item.companyImage" height="100%" />
        <div class="home_module_6_item_desc">
          <div class="home_module_6_item_desc_title">{{ item.companyName }}</div>
          <div class="home_module_6_item_desc_content">{{ item.companyDesc }}</div>
        </div>
      </div>
    </div>
    <div class="home_module_7">
      <div class="home_module_7_bg">
        <div class="home_module_7_title">了解中峪农业最新要闻</div>
        <div class="home_module_7_content">
          <div class="home_module_7_content_left">
            <div class="home_module_7_content_left_cover"
              :style="'background-image: url(' + newsList[newsIndex].newsCover + ');'">
            </div>
            <div class="home_module_7_content_left_bg"></div>
          </div>
          <div class="home_module_7_content_right">
            <div class="home_module_7_content_right_title">{{ newsList[newsIndex].newsTitle }}</div>
            <div class="home_module_7_content_right_date">{{ newsList[newsIndex].publishTime }}</div>
            <div>
              <i class="el-icon-arrow-left icon-left" :class="newsIndex == 0 ? 'nodata' : ''"></i>
              <i class="el-icon-arrow-right icon-right" :class="newsIndex == 3 ? 'nodata' : ''"></i>
            </div>
            <div class="get_more">更多</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_module_8">
      <div class="home_module_8_head">
        <div class="home_module_8_head_type" :class="newsType == 0 ? ' active' : ''">新闻资讯</div>
        <div class="home_module_8_head_type" :class="newsType == 1 ? ' active' : ''">行业资讯</div>
        <div class="home_module_8_head_type" :class="newsType == 2 ? ' active' : ''">热点专题</div>
      </div>
      <div class="home_module_8_cover">
        <img :src="newsList[0].newsCover" />
        <img :src="newsList[1].newsCover" />
        <img :src="newsList[2].newsCover" />
      </div>
      <div class="home_module_8_date">
        <div>{{ newsList[0].publishTime }}</div>
        <div>{{ newsList[1].publishTime }}</div>
        <div>{{ newsList[2].publishTime }}</div>
      </div>
      <div class="home_module_8_title">
        <div>{{ newsList[0].newsTitle }}</div>
        <div>{{ newsList[1].newsTitle }}</div>
        <div>{{ newsList[2].newsTitle }}</div>
      </div>
      <div class="home_module_8_more">
        <div><img src="../../static/newImg2/more_default.png" width="28px" /></div>
        <div><img src="../../static/newImg2/more_default.png" width="28px" /></div>
        <div><img src="../../static/newImg2/more_default.png" width="28px" /></div>
      </div>
      <div class="home_module_8_content_right">
        <div>
          <i class="el-icon-arrow-left icon-left" :class="newsTypeIndex == 0 ? 'nodata' : ''"></i>
          <i class="el-icon-arrow-right icon-right" :class="newsTypeIndex == 3 ? 'nodata' : ''"></i>
        </div>
        <div class="get_more">更多</div>
      </div>
    </div>
    <div class="home_module_9">
      <div class="home_module_9_bg">
        <div class="home_module_9_title">药材合集</div>
        <div class="home_module_9_int">
          中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，<br>
          还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。
        </div>
        <div class="home_module_9_list">
          <div class="swiper-container swiper-container4">
            <div class="swiper-wrapper">
              <div class="swiper-slide home_module_9_list_item" @click="toBusiness(0)" data-name="0">
                <img src="../../static/image/薏苡仁.jpg" />
                <div class="home_module_9_list_item_name">
                  <div class="home_module_9_list_item_name_1">关药</div>
                  <div class="home_module_9_list_item_name_2">
                    主产地山海关以北、东北三省及内蒙古东部。如人参、鹿茸、细辛、辽五味子、防风、关黄柏、龙胆、平贝母、刺五加、升麻、蛤蟆油、甘草、麻黄、黄芪、赤芍、苍术等。
                  </div>
                </div>
              </div>
              <div class="swiper-slide home_module_9_list_item" @click="toBusiness(1)" data-name="1">
                <img src="../../static/image/小茴香.jpg" />
                <div class="home_module_9_list_item_name">
                  <div class="home_module_9_list_item_name_1">北药</div>
                  <div class="home_module_9_list_item_name_2">
                    主产地河北、山东、山西及内蒙古中部。如党参、酸枣仁、柴胡、白芷、北沙参、板蓝根、大青叶、青黛、黄芩、香附、知母、山楂、金银花、连翘、桃仁、苦杏仁、薏苡仁、小茴香、大枣等。
                  </div>
                </div>
              </div>
              <div class="swiper-slide home_module_9_list_item" @click="toBusiness(2)" data-name="2">
                <img src="../../static/image/红花.jpg" />
                <div class="home_module_9_list_item_name">
                  <div class="home_module_9_list_item_name_1">怀药</div>
                  <div class="home_module_9_list_item_name_2">
                    主产地河南，如著名的四大怀药-地黄、牛膝、山药、菊花；天花粉、瓜蒌、白芷、辛夷、红花、金银花、山茱萸等。
                  </div>
                </div>
              </div>
              <div class="swiper-slide home_module_9_list_item" @click="toBusiness(4)" data-name="4">
                <img src="../../static/image/菊花.jpg" />
                <div class="home_module_9_list_item_name">
                  <div class="home_module_9_list_item_name_1">浙药</div>
                  <div class="home_module_9_list_item_name_2">
                    主产地浙江，如著名的浙八味-浙贝母、白术、延胡索、山茱萸、玄参、杭白芍、杭菊花、杭麦冬；温郁金、莪术、杭白芷、栀子、乌梅、乌梢蛇等。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_module_10">

    </div>
  </div>
</template>

<script>
import { getCompanyList, getNewsList } from "@/api/index.js";
import Swiper from "swiper";
export default {
  name: "index",
  data() {
    return {
      imgIndex: 0,
      treeData: [],
      slideBottom: null,
      newsList: [{}, {}, {}],
      newsIndex: 0,
      newsType: 0,
      newsTypeIndex: 0
    };
  },
  mounted() {
    this.getCompanyList();
    this.getList();
    const that = this
    this.slideBottom = new Swiper(".swiper-container4", {
      initialSlide: 0,
      spaceBetween: 20,
      slidesPerView: 5,
      observer: true, //开启动态检查器，监测swiper和slide
      observeParents: true, //监测Swiper 的祖/父元素
      loop: true,
      on: {
        click: function (e) {
          if (e.clickedIndex == 8) {
            that.toBusiness(0);
          }
          if (e.clickedIndex == 9) {
            that.toBusiness(1);
          }
          if (e.clickedIndex == 10) {
            that.toBusiness(2);
          }
          if (e.clickedIndex == 11) {
            that.toBusiness(4);
          }
        },
      },
    });
    this.scrollInterval1 = setInterval(() => {
      this.$nextTick(() => {
        this.slideBottom.slideNext(1800);
      });
    }, 2000);
    // $countUp
    this.allCountUp()
  },
  beforeDestroy() {
  },
  methods: {
    changeImgIndex(index) {
      this.imgIndex = index
    },
    allCountUp() {
      new this.$countUp('ZZMJ', 7944).start();
      new this.$countUp('XSDW', 20).start();
      new this.$countUp('GZRY', 3600).start();
      new this.$countUp('QDSJ', 2023).start();
    },
    getList() {
      getNewsList({
        current: 1, size: 4, websiteId: 3
      }).then((res) => {
        this.newsList = res.data.list;
        this.newsList.forEach((item) => {
          const updateTime = item.publishTime.split("-");
          item.day = updateTime[1] + "-" + updateTime[2];
          item.year = updateTime[0];
          item.title =
            item.newsTitle.length > 30
              ? item.newsTitle.substring(0, 30) + "..."
              : item.newsTitle;
        });
      });
    },
    toBusiness(tabIndex) {
      this.$router.push({
        path: "/business",
        query: { tabIndex },
      });
    },
    getCompanyList() {
      getCompanyList({ current: 1, size: 4, pid: 3 }).then((res) => {
        this.treeData = res.data.list;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.home_head {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .home_head_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .home_head_bg {
    width: 100%;
    height: 100%;
  }
}

.home_map {
  width: 1700px;
  margin: 90px auto;
  display: flex;
  justify-content: space-between;

  .home_map_left {
    width: 40%;

    .home_map_left_title {
      font-weight: bold;
      font-size: 44px;
      color: #000000;
      line-height: 66px;
      margin-top: 24px;
    }

    .home_map_left_tips {
      margin-top: 22px;
      font-weight: bold;
      font-size: 16px;
      color: #353535;
      line-height: 22px;
    }

    .home_map_left_num {
      display: flex;

      .home_map_left_num_left {
        width: 50%;
      }

      .home_map_left_num_right {
        width: 50%;
      }

      .num {
        margin-top: 40px;
        font-weight: bold;
        font-size: 50px;
        color: #1E9409;
        line-height: 75px;
      }

      .tips {
        font-size: 16px;
        color: #202020;
        line-height: 24px;
      }
    }

  }

  .home_map_right {
    width: 70%;
    position: relative;

    .home_map_right_introduction {
      position: absolute;
      right: 24%;
      top: 50%;
      display: flex;
      align-items: center;

      .home_map_right_introduction_bg {
        padding: 23px;
        width: 354px;
        height: 120px;
        background: #136A2C;
        border-radius: 6px;

        div {
          margin-bottom: 8px;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 28px;
        }
      }

      .home_map_right_introduction_name {
        font-weight: bold;
        font-size: 20px;
        color: #3B3B3B;
        line-height: 28px;
        margin-left: 20px;
      }

      .home_map_right_introduction_pointer {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #1B7C2B;
        margin-left: 20px;
      }
    }
  }
}

.home_module_3 {
  position: relative;
  width: 100%;

  .imgs {
    width: 100%;
    height: 788px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .imgs_item {
      transition: width 1s ease-in-out;
      width: 18%;
      object-fit: cover;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .imgs_tips {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    .imgs_tips_item {
      text-align: center;
      transition: width 1s ease-in-out;
      width: 18%;
      cursor: pointer;
      overflow: hidden;

      .imgs_tips_item_bg {
        font-weight: bold;
        font-size: 60px;
        color: #FFFFFF;
        line-height: 84px;
        letter-spacing: 4px;
      }

      .imgs_tips_item_tips {
        width: 650px;
        margin: 0 auto;
        font-size: 21px;
        color: #FFFFFF;
        line-height: 38px;
        letter-spacing: 2px;


        .imgs_tips_item_tips_head {
          margin-bottom: 44px;
          color: #FFFFFF;
          line-height: 84px;
          letter-spacing: 4px;
          font-size: 70px;
        }
      }
    }
  }

  .active {
    width: 64% !important;
  }
}

.home_module_4 {
  height: 570px;
  width: 100%;
  position: relative;

  .home_module_4_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    text-align: center;

    .home_module_4_content_title {
      font-weight: bold;
      font-size: 48px;
      line-height: 72px;
      letter-spacing: 2px;
    }

    .home_module_4_content_desc {
      margin-top: 45px;
      width: 1200px;
      font-size: 20px;
      color: #2E2E2E;
      line-height: 36px;
    }

    .home_module_4_content_more {
      margin: 72px auto 0;
      width: 170px;
      height: 54px;
      border-radius: 27px;
      border: 1px solid #000000;
      font-weight: 500;
      font-size: 16px;
      color: #060606;
      line-height: 54px;
    }
  }
}

.home_module_5 {
  width: 100%;
  height: 800px;
  background: url("../../static/newImg2/home_bg3.jpg") no-repeat;
  background-size: cover;
}

.home_module_6 {
  display: flex;
  justify-content: center;
  margin: 80px auto 145px auto;
  width: 1500px;

  .home_module_6_item {
    width: 50%;
    height: 500px;
    overflow: hidden;
    position: relative;

    .home_module_6_item_desc {
      padding: 30px;
      position: absolute;
      left: 60px;
      top: 60px;
      width: 60%;
      height: 200px;
      background: #FBFBFC;

      .home_module_6_item_desc_title {
        font-weight: bold;
        font-size: 28px;
        color: #157B2F;
        line-height: 31px;
        text-align: left;
      }

      .home_module_6_item_desc_content {
        margin-top: 24px;
        font-size: 18px;
        color: #4F4F4F;
        line-height: 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }

    .home_module_6_item_desc::after {
      content: " ";
      position: absolute;
      bottom: -33px;
      left: 33px;
      width: 66px;
      height: 66px;
      background: #33811D;
    }
  }
}

.home_module_7 {
  width: 100%;
  height: 960px;
  background: url("../../static/newImg2/home_bg3.png") no-repeat;
  background-size: cover;
  position: relative;

  .home_module_7_bg {
    width: 1500px;
    margin: 0 auto;

    .home_module_7_title {
      padding: 145px 0 90px 0;
      font-weight: bold;
      font-size: 48px;
      color: #000000;
      line-height: 72px;
      letter-spacing: 2px;
    }

    .home_module_7_content {
      display: flex;
      justify-content: space-between;

      .home_module_7_content_left {
        width: 48%;
        position: relative;
        transform-style: preserve-3d;
        perspective: 1200px;

        .home_module_7_content_left_cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 40px;
          background-repeat: no-repeat;
          background-size: cover;
          z-index: 2;
          transform: translate3d(0px, 0px, -100px) rotateY(10deg);
        }

        .home_module_7_content_left_bg {
          position: absolute;
          top: 30px;
          left: -30px;
          border-radius: 40px;
          content: " ";
          width: 100%;
          height: 100%;
          background-color: #E3E3E3;
          opacity: 0.6;
          z-index: 1;
          transform: translate3d(0px, 0px, -100px) rotateY(10deg);
        }
      }

      .home_module_7_content_right {
        width: 50%;

        .home_module_7_content_right_title {
          font-weight: bold;
          font-size: 35px;
          color: #000000;
          line-height: 42px;
        }

        .home_module_7_content_right_date {
          margin: 40px 0 100px 0;
        }

        .icon-left,
        .icon-right {
          border: 1px solid #1D1B19;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 20px;
          cursor: pointer;
        }

        .icon-left {
          margin-right: 20px;
        }

        .nodata {
          color: #CBCBCB;
          border: 1px solid #CBCBCB;
        }
      }
    }
  }

}

.home_module_8 {
  padding-top: 130px;
  width: 1500px;
  margin: 0 auto;

  .home_module_8_head {
    display: flex;

    .home_module_8_head_type {
      font-weight: bold;
      font-size: 28px;
      color: #8C8F92;
      line-height: 42px;
      letter-spacing: 1px;
      margin-right: 80px;
    }

    .active {
      font-size: 48px;
      color: #000000;
      letter-spacing: 2px;
    }
  }

  .home_module_8_cover {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 80px;

    img {
      width: 30%;
    }
  }

  .home_module_8_date {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 48px;

    div {
      width: 30%;
      font-size: 16px;
      color: #211F1F;
      line-height: 24px;
    }
  }

  .home_module_8_title,
  .home_module_8_more {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 28px;

    div {
      width: 30%;
      font-weight: bold;
      font-size: 28px;
      color: #211F1F;
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .home_module_8_content_right {
    margin: 100px 0;

    .home_module_8_content_right_title {
      font-weight: bold;
      font-size: 35px;
      color: #000000;
      line-height: 42px;
    }

    .home_module_8_content_right_date {
      margin: 40px 0 100px 0;
    }

    .icon-left,
    .icon-right {
      border: 1px solid #1D1B19;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      cursor: pointer;
    }

    .icon-left {
      margin-right: 20px;
    }

    .nodata {
      color: #CBCBCB;
      border: 1px solid #CBCBCB;
    }
  }
}

.home_module_9 {
  width: 100%;
  background: url("../../static/newImg/位图1.png") no-repeat;
  background-size: cover;
  padding: 50px 0;

  .home_module_9_bg {
    width: 80%;
    margin: 0 auto;
  }

  .home_module_9_title {
    margin-top: 50px;
    font-family: 宋体;
    font-weight: 400;
    color: #202020;
    line-height: 50px;
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 7px;
  }

  .home_module_9_int {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 32px;
    letter-spacing: 2px;
    margin: 55px 0;
  }

  .home_module_9_list {
    padding: 45px 33px;
    display: flex;
    justify-content: space-between;

    .home_module_9_list_item {
      cursor: pointer;
      transition: all 0.5s ease-out;
      position: relative;

      img {
        width: 100%;
      }

      .home_module_9_list_item_name {
        background: linear-gradient(180deg,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.82) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        font-family: 宋体;
        font-weight: bold;
        font-weight: 400;
        color: #ffffff;

        .home_module_9_list_item_name_1 {
          font-size: 36px;
          line-height: 50px;
          margin-top: 20px;
        }

        .home_module_9_list_item_name_2 {
          font-size: 12px;
          line-height: 17px;
          text-align: left;
          padding: 20px 20px 0 20px;
        }
      }
    }
  }
}

.get_more {
  font-weight: bold;
  font-size: 44px;
  color: #000000;
  letter-spacing: 2px;
  margin-top: 40px;
  position: relative;
  width: fit-content;
}

.get_more::after {
  position: absolute;
  content: " ";
  bottom: -10px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #2E2E2E;
}
</style>